import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/kR6fO6h_Q18">
    <SEO title="In the Fire - Living in Babylon" />
  </Layout>
)

export default SermonPost
